import {
  Card,
  Form,
  FormLayout,
  Frame,
  Heading,
  Layout,
  Page,
  TextField,
  Toast,
} from "@shopify/polaris";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import API from "../../api";
import Logo from "../../public/assets/Logo.svg";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    storeUrl: "",
  });
  const [endPointError, setEndPointError] = useState(false);

  const router = useRouter();
  const { storeId } = router.query;

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) window.location.href = window.location.origin;
    // localStorage.clear();
  }, []);

  useEffect(() => {
    if (router?.query?.email) {
      setEmail(router.query.email);
    }
  }, [router.query]);

  const handleSubmit = () => {
    const payload = {
      email,
      password,
      ...(storeId ? { storeId } : {}),
    };
    if (!payload.email.length)
      setErrors((previous) => ({ ...previous, email: "email is required" }));
    if (!payload.password.length)
      setErrors((previous) => ({
        ...previous,
        password: "password is required",
      }));
    else {
      setErrors({});
      API("/auth/login", "POST", payload)
        .then((res) => {
          localStorage.setItem("token", res.result.token);
          window.location.href = window.location.origin;
        })
        .catch((error) => {
          // console.log("ERROR:", error);
          setErrorMessage(error.error);
          setEndPointError(true);
        });
    }
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    setErrors((previous) => ({
      ...previous,
      email: "",
    }));
  };
  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrors((previous) => ({
      ...previous,
      password: "",
    }));
  };

  return (
    <Frame>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#4c0b84",
        }}
      >
        <div style={{ width: "500px" }}>
          <Page>
            <Layout>
              <Layout.Section>
                <Card sectioned>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Image
                      alt=""
                      width="200px"
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      src={Logo}
                    />
                    <Heading>Welcome to Ecom360</Heading>
                    <p>Please sign-in to your account</p>

                    <div style={{ marginTop: "2.5rem", width: "100%" }}>
                      <Form onSubmit={handleSubmit}>
                        <FormLayout>
                          <TextField
                            id="email"
                            type="email"
                            label="Email"
                            onChange={handleEmailChange}
                            value={email}
                            error={errors.email}
                          />
                          <TextField
                            id="password"
                            type="password"
                            label="Password"
                            onChange={handlePasswordChange}
                            value={password}
                            error={errors.password}
                          />
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              background: "#4c0b84",
                              color: "white",
                              padding: 10,
                              borderRadius: 7,
                              marginBottom: 25,
                              marginTop: 30,
                              cursor: "pointer",
                            }}
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Login
                          </div>
                        </FormLayout>
                      </Form>
                    </div>
                  </div>
                </Card>
                <br />
              </Layout.Section>
              {endPointError ? (
                <Toast
                  content={errorMessage}
                  error
                  onDismiss={() => setEndPointError(false)}
                />
              ) : null}
            </Layout>
          </Page>
        </div>
      </div>
    </Frame>
  );
};

export default LoginPage;
